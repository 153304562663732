import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  ClientSideComponent,
  Text,
} from 'components';
import { ClickTracker } from 'components/Tracking';
import {
  MENU_LINK_TARGET_TYPES,
} from 'utils/constants';
import {
  FooterContainer,
  FooterLink,
} from './FooterStyles';
import FooterImages from './FooterImages';

const getLinkTarget = link => (link.linkTarget === MENU_LINK_TARGET_TYPES.blank ? '_blank' : undefined);

function Footer() {
  const { footerFeature } = useSelector(state => state.settings.features);
  const brandName = useSelector(state => state.settings.brand.name);
  const { footerLinks } = useSelector(state => state.settings);

  return (
    <FooterContainer>
      {footerFeature
        && (
        <Box flexBox wrap spaceBetween mb="medium" columnGap="xlarge">
          { footerFeature.imagesLeft && footerFeature.imagesLeft.length > 0
          && (
            <Box xs-width="100%" xs-textAlign="center" textAlign="left" mr="auto" display="block" alignContent="center">
              {
                footerFeature.imagesLeft.map((oneRow, rowKey) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FooterImages oneRow={oneRow} key={rowKey} />
                ))
              }
            </Box>
          )}

          {footerFeature.imagesCenter && footerFeature.imagesCenter.length > 0
          && (
            <Box column xs-width="100%" xs-textAlign="center" textAlign="center" mx="auto" display="block" alignContent="center">
              {
                footerFeature.imagesCenter.map((oneRow, rowKey) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FooterImages oneRow={oneRow} key={rowKey} />
                ))
              }
            </Box>
          )}

          {footerFeature.imagesRight && footerFeature.imagesRight.length > 0
          && (
            <Box xs-width="100%" xs-textAlign="center" textAlign="right" ml="auto" display="block" alignContent="center">
              {
                footerFeature.imagesRight.map((oneRow, rowKey) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FooterImages oneRow={oneRow} key={rowKey} />
                ))
              }
            </Box>
          )}
        </Box>
        )}
      <Box row wrap mb="fine">
        {footerLinks.map(link => (
          <ClickTracker
            key={link.title}
            component="Settings"
            action="click"
            clickType="navigation"
            context={{ key: link.trackingKey }}
            element="external_links"
          >
            <FooterLink href={link.url} target={getLinkTarget(link)}>
              {link.title}
            </FooterLink>
          </ClickTracker>
        ))}
      </Box>

      <ClientSideComponent>
        <Text
          id="copyrightNotice"
          values={{
            brandName,
            year: new Date().getFullYear(),
          }}
          color="secondary"
          fontSize="fine"
          align="center"
        />
      </ClientSideComponent>
    </FooterContainer>
  );
}

export default React.memo(Footer);
